<template>
    <div>
        <page-header :title="`Danh sách cán bộ`" :items="breadcrumbs" />
        <div class="card">
            <div class="card-body">
                <div class="mb-2">
                    <product-filter />
                    <div class="col-sm-2">
                        <div class="text-sm-right">
                        </div>
                    </div>
                </div>
                <!-- Table data -->
                <div v-show="!loading" class="table-responsive">
                    <table class="table table-hover table-centered table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th style="min-width: 100px;">Hình ảnh</th>
                                <th scope="col">Số ĐT / Email</th>
                                <th scope="col" class="text-center">Tên</th>
                                <th scope="col" class="text-center style-width">Địa chỉ</th>
                                <th scope="col" style="min-width: 120px;">Cấp Quyền</th>
                                <th scope="col">Chức vụ</th>
                                <th scope="col">Xác thực</th>
                                <th scope="col" style="width: 50px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in entries" :key="entry.Id">
                                <td>
                                    <img :src="addPrefixImage(entry.Avatar)" class="avatar-md"
                                        @error="replaceBrokenImage" />
                                </td>
                                <td>
                                    {{ entry.Id }}
                                </td>
                                <td>
                                    {{ entry.Name | capitalising }}
                                </td>
                                <td>
                                    {{ entry.Address }}, {{ entry.Ward }}, {{ entry.District }}, {{ entry.Province }}
                                </td>
                                <td>
                                    {{ mapCapQuyen(entry.RoleLevel) }}
                                </td>
                                <td>
                                    {{ entry.Position }}
                                </td>
                                <td style="width: 120px">
                                    <b-button v-if="entry.Verify == 1" variant="outline-success" size="sm" disabled>Đã
                                        xác thực</b-button>
                                    <b-button v-else-if="entry.Verify == 2" variant="outline-warning" size="sm"
                                        disabled>Chờ xác thực</b-button>
                                    <b-button v-else variant="outline-danger" size="sm" disabled>Chưa xác thực
                                    </b-button>
                                </td>
                                <td class="text-right" style="font-size: 16px">
                                    <router-link v-if="(role_admin == true) ||
                                    (role_level == PROVINCE_OFFICIAL && entry.RoleLevel == DISTRICT_OFFICIAL) ||
                                    (role_level == DISTRICT_OFFICIAL && entry.RoleLevel == WARD_OFFICIAL) ||
                                    (role_level == WARD_OFFICIAL && entry.RoleLevel == PROCESS_OFFICIAL)" v-b-tooltip.hover
                                        :title="`Sửa`" class="text-primary"
                                        :to="{ name: 'admin.users.edit', params: { id: entry.RawId, form: entry } }">
                                        <i class="bx bx-edit"></i>
                                    </router-link>
                                    <a v-if="(role_admin == true) ||
                                    (role_level == PROVINCE_OFFICIAL && entry.RoleLevel == DISTRICT_OFFICIAL) ||
                                    (role_level == DISTRICT_OFFICIAL && entry.RoleLevel == WARD_OFFICIAL) ||
                                    (role_level == WARD_OFFICIAL && entry.RoleLevel == PROCESS_OFFICIAL)"
                                        href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá`" class="text-danger"
                                        @click.prevent="deleteEntry(entry)"> <i class="bx bx-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- loading -->
                <div v-show="loading" class="text-center mt-5">
                    <b-spinner variant="primary" label="Text Centered"></b-spinner>
                </div>
                <!-- table information-->
                <div style="font-size: 14px;" class="row align-items-center">
                    <div class="col-sm-8 col-xs-6 text-right">
                        <table-info :pagination="pagination" />
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <table-pagination :pagination="pagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import TableInfo from '../../../components/datatable/table-info';
import TablePagination from '../../../components/datatable/table-pagination';
import ProductFilter from './partials/product-filter';
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    httpPreview
} from "../../../_utils/httpPreview";
import {
    Base64
} from 'js-base64';
import {
    PROVINCE_OFFICIAL,
    DISTRICT_OFFICIAL,
    WARD_OFFICIAL,
    PROCESS_OFFICIAL,
    USER
} from '../../../role/checkTypeRole'

export default {
    name: 'list',
    components: {
        ProductFilter,
        TablePagination,
        TableInfo,
        PageHeader,
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Cán bộ',
            to: '/admin/users',
            active: true,
        },],
        entries: [],
        pagination: {
            limit: 10,
            skip: 0,
            total: 0,
            page: 1,
            search: '',
        },
        userName: "",
        role_level: null,
        role_admin: false,
        PROVINCE_OFFICIAL,
        DISTRICT_OFFICIAL,
        WARD_OFFICIAL,
        PROCESS_OFFICIAL,
        USER,
        loading: true,
    }),
    watch: {
        '$route.query': {
            handler: function () {
                this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
            },
        },
    },
    methods: {
        firstPage() {
            let query = {
                ...this.$route.query
            };
            delete (query.page);
            this.$router.replace({
                name: this.$route.name,
                query: query,
            }).catch(err => err);
        },
        mapCapQuyen(level) {
            switch (level) {
                case 0:
                    return 'Quản trị hệ thống'
                case 1:
                    return 'Cán bộ Tỉnh'
                case 2:
                    return 'Cán bộ Huyện'
                case 3:
                    return 'Cán bộ Xã'
                case 4:
                    return 'Cán bộ xử lý'
                case 99:
                    return 'Cá nhân'
                default:
                    return 'Cá nhân'
            }
        },
        mapQuyen(level) {
            switch (level) {
                case 'admin':
                    return 'Quản trị viên'
                case 'user':
                    return 'Người dùng'
                default:
                    return 'Người dùng'
            }
        },
        async getData(skip = 0, payload = {}) {
            this.loading = true
            if (isNaN(skip)) {
                skip = 0
            }
            this.pagination.skip = skip
            let body = {}

            if (payload.verify !== undefined && payload.verify !== "") {
                body = {
                    ...body,
                    "verify": Number(payload.verify)
                }
            }
            if (payload.province) {
                body = {
                    ...body,
                    "province": payload.province
                }
            }
            if (payload.district) {
                body = {
                    ...body,
                    "district": payload.district
                }
            }
            if (payload.ward) {
                body = {
                    ...body,
                    "ward": payload.ward
                }
            }
            let response = await httpClient.post(`user/find/officiers`, body, {
                params: {
                    ...this.pagination,
                    search: payload.search
                }
            }).catch(err => err)
            for (let i = 0; i < this.entries.length; i++) {
                if (this.entries[i].Verify == 1) {
                    this.entries[i].Name = this.entries[i].Kyc.hoTen
                }
            }
            if (response.status === 200 && response.data.data) {
                this.entries = response.data.data

                this.pagination = {
                    ...this.pagination,
                    total: response.data.total,
                    page: Math.ceil(skip / this.pagination.limit) + 1
                }
            } else {
                this.entries = []
                this.pagination = {
                    ...this.pagination,
                    total: 0,
                    page: 1
                }
            }
            if (this.entries.length == 0) {
                this.loading = false
                this.firstPage();
                return true;
            }
            this.loading = false
        },
        replaceBrokenImage(event) {
            event.target.src = require('@/assets/images/no-image.png');
        },
        addPrefixImage(src) {
            if (src && src.startsWith(`http`)) return src
            return httpPreview + src
        },
        async deleteEntry(entry) {
            await Swal.queue([{
                title: 'Xoá người dùng: ' + entry.Name,
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`user/delete`, {
                        userId: entry.Id
                    })
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Xoá thành công',
                        });
                        await this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
                    } else {
                        Swal.insertQueueStep({
                            title: 'Xoá không thành công',
                        });
                    }
                    return true;
                },
            },]);
        },
        getUserData() {
            let userData = localStorage.getItem("token").split(".")[1];
            let userInfo = JSON.parse(Base64.decode(userData));
            this.role_level = userInfo.role_level
            this.role_admin = userInfo.role.includes('admin')
        }
    },
    created() {
        this.getUserData();
        this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
    },
};
</script>

<style scoped>
.style-width {
    min-width: 140px;
}

.style-top {
    transform: translateY(25vh);
}
</style>
